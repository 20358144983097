import React from 'react';
import { graphql, useStaticQuery } from 'gatsby';
import Container from '../../../primitives/grid/container';
import Row from '../../../primitives/grid/row';
import Col from '../../../primitives/grid/col';
import CustomStructuredTextRenderer from '../../custom-structured-text-renderer';
import Typography from '../../../primitives/typography';
import { colors } from '../../../primitives/colors';
import CascadeChildren from '../../../animations/transitions/cascade-children';
import { useLanguage } from '../../../language/language-provider';
import Link from '../../../primitives/link';
import { breakpoints } from '../../../primitives/tokens';

export type JobsListProps = Queries.JobsListPropsFragment;

const JobsList = ({
    richTitle,
    richText,
}: JobsListProps): JSX.Element => {
    const { language } = useLanguage();
    const staticData = useStaticQuery<Queries.JobsDataQuery>(graphql`
        query JobsData {
            au: allDatoCmsJob(locale: "en-AU") {
                nodes {
                    title
                    summary
                    postingDate(formatString: "do MMMM yyyy")
                    hideForLocale
                    permalink
                    positionStatus
                }
            }
            uk: allDatoCmsJob(locale: "en-GB") {
                nodes {
                    title
                    summary
                    postingDate(formatString: "do MMMM yyyy")
                    hideForLocale
                    permalink
                    positionStatus
                }
            }
            us: allDatoCmsJob(locale: "en-US") {
                nodes {
                    title
                    summary
                    postingDate(formatString: "do MMMM yyyy")
                    hideForLocale
                    permalink
                    positionStatus
                }
            }
        }
    `);

    const {
        nodes: jobs,
    } = staticData[language as 'au' | 'uk' | 'us'];

    const filteredJobs = (jobs && jobs.filter(x => !x.hideForLocale && x.positionStatus === 'open')) || [];

    return (
        <Container css={{
            marginTop: '80px',
            marginBottom: '80px',
        }}
        >
            <Row>
                <Col breakpoints={{
                    dt: { span: 1 }, tb: { span: 0 },
                }}
                />
                <Col breakpoints={{
                    dt: { span: 10 }, tb: { span: 12 }
                }}
                >
                    <CascadeChildren
                        as="ul"
                        css={{
                            gap: '30px',
                            display: 'flex',
                            flexDirection: 'column',
                            listStyleType: 'none',
                            [breakpoints.mb]: {
                                paddingTop: '32px',
                            },
                        }}
                    >
                        {filteredJobs.length === 0 && (
                            <Typography
                                fontSize={{
                                    dt: 1822,
                                }}
                                color="primaryOrange"
                                fontWeight="medium"
                                override
                                css={{
                                    display: 'inline-flex',
                                    gap: '10px',
                                    alignItems: 'center',
                                }}
                            >
                                No jobs are currently open at this stage.
                            </Typography>
                        )}
                        {filteredJobs.map(item => (
                            <li css={{
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'space-between',
                                borderBottom: `1px solid ${colors.grey100}`,
                                paddingBottom: '30px',
                            }}
                            >
                                <Link to={item.permalink}>
                                    <Typography
                                        fontSize={{
                                            dt: 1822,
                                        }}
                                        color="primaryOrange"
                                        fontWeight="medium"
                                        override
                                        css={{
                                            display: 'inline-flex',
                                            gap: '10px',
                                            alignItems: 'center',
                                        }}
                                    >
                                        {item.title}
                                    </Typography>
                                    <Typography
                                        fontSize={{
                                            dt: 1420,
                                        }}
                                        css={{
                                            marginTop: '8px',
                                        }}
                                        override
                                    >
                                        {item.summary}
                                    </Typography>
                                    <Typography
                                        fontSize={{
                                            dt: 1322,
                                        }}
                                        css={{
                                            marginTop: '8px',
                                            fontStyle: 'italic',
                                        }}
                                        override
                                    >
                                        Posted
                                        {' '}
                                        {item.postingDate}
                                    </Typography>
                                </Link>
                            </li>
                        ))}
                    </CascadeChildren>
                </Col>
            </Row>
        </Container>
    );
};

export default JobsList;

export const query = graphql`
    fragment JobsListProps on DatoCmsJobsList {
        __typename
    }
`;
